<template>
  <div class="register" :style="backgroundDiv">
    <a-col span="24" class="loginTitle">
      <img src="../assets/logo-h5.png" />
      <span>翻红馆</span>
    </a-col>
    <div class="button_download" @click="download('Android')">Android版本下载</div>
    <div class="button_download" @click="download('iOS')">iOS版本下载</div>
    <!-- <div class="tip">
      <span>有疑问，请咨询客服</span>
      <img src="../assets/arrow-h5.png" />
    </div> -->
    <div class="showToast" v-if="showToast">
      <div class="showToast_con">{{ showToast }}</div>
    </div>

    <div class="success_tip" v-if="success_tip" @click="closeTip">
      <img src="../assets/register-tip.png" />
    </div>
    <div class="download_tip" v-if="download_tip">
      <div class="download_con">
        <div class="title">提示信息</div>
        <div class="content">
          iOS版本正在审核中，您可以在网页登录后，邀请好友加入
        </div>
        <div class="button">
          <a @click="hideTip">取消</a>
          <router-link tag="a" to="/loginApp" class="gotoLogin"
            >去登录</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'lib-flexible'

import { resetRem } from "@/assets/rem.js";
export default {
  data() {
    return {
      backgroundDiv: {
        backgroundImage: "url(" + require("../assets/bg-h5.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      nickname: "",
      download_tip: false,
      success_tip: false,
      avatar: "",
      invite_code: "",
      invite_link: "",
      showToast: "",
      mobile: "",
      authCount: "",
      count: "",
      url: "",
    };
  },
  created() {
    resetRem();
  },
  mounted() {
    // this.getUserInfo();
    // this.friendInfo();
    // this.appPack();
  },
  methods: {
    appPack() {
      var that = this;
      that.axios.get("/app/open/appPack/release").then((res) => {
        //   that.axios.get("/Api/admin/city/edit",{params: data}).then((res)=>{
        if (that.$code(res)) {
          that.url = res.data.data.url;
        }
      });
    },
    closeTip() {
      this.success_tip = false;
    },
    copy() {
      var that = this;
      var clipboard = new this.Clipboard(".copy");
      clipboard.on("success", () => {
        that.showToast = "复制成功";
        setTimeout(function () {
          that.showToast = "";
        }, 2000);
        clipboard.destroy();
      });
      // 浏览器不支持
      clipboard.on("error", () => {
        that.showToast = "不支持";
        setTimeout(function () {
          that.showToast = "";
        }, 2000);
        // 释放内存
        clipboard.destroy();
      });
    },
    hideTip() {
      this.download_tip = false;
    },
    download(e) {
      var that = this;
      if (e == "iOS") {
        // that.download_tip = true;
        that.url="https://apps.apple.com/cn/app/%E7%BF%BB%E7%BA%A2%E9%A6%86-%E4%B8%80%E6%AC%BE%E9%9D%A0%E7%9F%AD%E8%A7%86%E9%A2%91%E8%B5%9A%E9%92%B1%E7%9A%84app/id1552911522"
      } else {
        that.url="https://a.app.qq.com/o/simple.jsp?pkgname=com.xingke.fanhongguan"
      }
      window.location.href=that.url
        // var ua = window.navigator.userAgent.toLowerCase();
        // //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        // if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //   this.success_tip = true;
        //   var token = window.localStorage.getItem("token")
        //     ? window.localStorage.getItem("token")
        //     : this.$route.query.token;
        //   this.$router.push({ path: this.$route.path + "?token=" + token });
        // } else {
        //   this.success_tip = false;
        //   let link = document.createElement("a");
        //   link.style.display = "none";
        //   link.href = this.url;
        //   // link.setAttribute('download', "fanhong0.8.apk")// 文件名
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link); // 下载完成移除元素
        //}
    },
  },
};
</script>
<style scoped>
.register {
  width: 10rem;
  margin: 0 auto;
  height: 100vh;
  background: url("../assets/bg-h5.png") no-repeat 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.register .loginTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -2.2rem;
  margin-bottom:0.64rem;
}
.register .loginTitle img {
  width: 2.56rem;
  margin-bottom: 0.21333rem;
}
.register .loginTitle span {
  font-family: PingFang-SC-Heavy;
  font-size: 0.64rem;
  line-height: 0.88rem;
  color: #ddb383;
  letter-spacing: 0;
  text-align: center;
}
.showToast {
  width: 10rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
}
.showToast_con {
  height: 1.333rem;
  line-height: 1.333rem;
  background: #4c4c4c;
  border-radius: 0.667rem;
  padding: 0 1.28rem;
  font-family: PingFang-SC-Regular;
  font-size: 0.3733rem;
  color: #ffffff;
  letter-spacing: 0;
}
.button_download {
  display: block;
  width: 7.6267rem;
  height: 1.3333rem;
  line-height: 1.3333rem;
  border-radius: 0.1067rem;
  border: 0;
  font-family: PingFangSC-Regular;
  font-size: 0.4267rem;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  margin: 0.64rem auto 0;
  background-image: linear-gradient(-45deg, #ddb383, #b17b55);
}
.gotoLogin {
  display: block;
  width: 7.6267rem;
  height: 1.3333rem;
  line-height: 1.3333rem;
  background: rgba(221, 179, 131, 0.1);
  border-radius: 0.1067rem;
  border: 0;
  font-family: PingFangSC-Regular;
  font-size: 0.4267rem;
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto;
  color: #ddb383;
  /* margin-top:-0.32rem */
}
.download_tip {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}
.download_con {
  width: 8.72rem;
  height: 5.4933rem;
  border-radius: 0.32rem;
  background: #fff;
  padding: 0.533rem 0.8rem 0.8rem;
}
.download_tip .title {
  font-family: PingFangSC-Regular;
  font-size: 0.5333rem;
  line-height: 0.7467rem;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0.32rem;
}
.download_tip .content {
  font-family: PingFangSC-Regular;
  font-size: 0.3733rem;
  color: #666666;
  letter-spacing: 0;
  line-height: 0.68rem;
}
.download_tip .button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 0.48rem;
}
.download_tip .button * {
  border: 1px solid #979797;
  border-radius: 0.107rem;
  width: 3.0933rem;
  height: 1.333rem;
  line-height: 1.333rem;
  text-align: center;
  font-size: 0.4267rem;
  color: #333333;
  box-sizing: border-box;
}
.download_tip .button a:last-child {
  color: #fff;
  background-image: linear-gradient(-45deg, #ddb383 0%, #b17b55 100%);
  border: 0;
}
.success_tip {
  position: fixed;
  top: 0;
  left: 0;
  width: 10rem;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.success_tip img {
  width: 10rem;
}
</style>